/**/
body, html {
background: #FBEDCD;
}
body{
font-family: Arial, Helvetica, sans-serif;
font-size: 16px;
color: #061917;
}
.login form{
background: #ffffff;
}
.login label{
font-size: 16px;
}
#login h1 a{
width: 100%!important;
height: 100px!important;
background-image: url(hofe_img/logo.png)!important;
background-size: auto 100%!important;
background-position: center;
background-repeat: no-repeat;
margin: 0 0 25px;
}
.login form .forgetmenot{
float: none;
}
#login form p.forgetmenot{
margin-bottom: 16px;
}
.login .button-primary{
float: none;
}
.wp-core-ui .button.button-large{
height: 46px;
padding: 0 20px 2px;
}
.wp-core-ui .button-primary{
display: block;
width: 100%;
padding: 15px 20px 12px;
background: #1B665D;
border: 1px solid #1B665D;
box-shadow: none;
text-transform: uppercase;
text-shadow: none;
transition: all 0.3s;
}
.wp-core-ui .button-primary:hover{
background: #FFF;
color: #1B665D;
border: 1px solid #1B665D;
transition: all 0.3s;
}